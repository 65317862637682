input[type='radio'] {
  display: inline !important;
}

/* Animation */
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.integry-apps-header {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 400;
  color: white;
}

#x-integry-container
  .styles-module_button__osBYJ.styles-module_btnSecondary__3rdEx {
  background: #fff;
  border: 1px solid #ff5000;
  color: #ff5000;
}

#x-integry-container
  .styles-module_button__osBYJ.styles-module_btnSecondary__3rdEx:hover {
  background: #ff5000 !important;
  border: 1px solid #ff5000 !important;
  color: white;
}

#x-integry-container
  .styles-module_button__osBYJ.styles-module_btnPrimary__2lzrP {
  border: 1px solid #ff5000;
  background: #ff5000;
  color: white;
}

#x-integry-container #setupContainer {
  margin: 0 auto;
}

#x-integry-container .styles-module_backLink__1Hpzi circle {
  stroke: #ff5000;
}

#x-integry-container .styles-module_backLink__1Hpzi path {
  fill: #ff5000;
}

#x-integry-container .styles-module_authActions__3bjdW {
  display: none;
}
